import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
const AppRouter = lazy(() => import("./routes"))
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div className="splash_screen">Loading...
        </div>}>
          <AppRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export default App
