// src/features/api/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {logoutAction} from "../slices/authSlice"

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.authData?.token; // Assuming you have auth in your state
        console.log("token",token);
        
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
const baseQueryWithAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // If we get a 401, dispatch the logout action
        api.dispatch(logoutAction());
    }
    return result;
};

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithAuth, // Replace with your API base URL
    endpoints: (builder) => ({
        dynamicQuery: builder.query({
            query: ({ endpoint, params, key, headers }) => {
                const queryParams = new URLSearchParams(params).toString();
                return {
                    url: `${endpoint}?${queryParams}`,
                    method: 'GET',
                    headers: headers || {}, // Use provided headers or an empty object
                };
            },
            providesTags: (result, error, { key }) => [key],
        }),
        dynamicMutation: builder.mutation({
            query: ({ endpoint, method = 'POST', body, headers }) => {
                // console.log("key@@@", key);
                return {
                    url: endpoint,
                    method: method,
                    body,
                    headers: headers || {},
                }
            },
            invalidatesTags: (result, error, { key }) => {
                console.log("!!", key);
                return key ? [key] : []
            },
        }),
    }),
});

export const { useDynamicQueryQuery, useDynamicMutationMutation } = apiSlice;
